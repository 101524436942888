export default () => ({
  ENV: process.env.ENV || 'development',
  ACTIVITY_INTERVAL: process.env.ACTIVITY_INTERVAL,
  API_ACCESS_INTRANALYTICS: process.env.API_ACCESS_INTRANALYTICS,
  ASSETS_DOMAIN: process.env.ASSETS_DOMAIN,
  AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
  AUTHENTICATION_INTERVAL: process.env.AUTHENTICATION_INTERVAL,
  AXS_DOMAIN: process.env.AXS_DOMAIN,
  GLOBAL_API_GATEWAY_BACKOFFICE: process.env.GLOBAL_API_GATEWAY_BACKOFFICE,
  GLOBAL_API_GATEWAY: process.env.GLOBAL_API_GATEWAY,
  GLOBAL_ASSETS_DOMAIN: process.env.GLOBAL_ASSETS_DOMAIN,
  GLOBAL_IMPORTMAP_URL: process.env.GLOBAL_IMPORTMAP_URL,
  GLOBAL_PUB_APPS: process.env.GLOBAL_PUB_APPS,
  GLOBAL_PUBLIC_RESOURCES: process.env.GLOBAL_PUBLIC_RESOURCES,
  GTM_KEY_PORTAL_TRACK: process.env.GTM_KEY_PORTAL_TRACK,
  GTM_KEY: process.env.GTM_KEY,
  INTRANALYTICS_URL: process.env.INTRANALYTICS_URL,
  LANDING_URL: process.env.LANDING_URL,
  LMS_URL: process.env.LMS_URL,
  LEARNING_MAP_URL: process.env.LEARNING_MAP_URL,
  NEW_LMS_URL: process.env.NEW_LMS_URL,
})
